<template>
    <div class="tree">
        <ul>
            <li>
                <a href="#">
                    <div class="row-1-title heda size14">书记（马峰）</div>
                </a>
                <ul>
                    <li>
                        <a href="#">
                            <div class="row-2-title heda">
                                <span class="size14">第一支部书记</span>
                                <span class="size14">(熊新军)</span>
                            </div>
                        </a>
                        <ul>
                            <li>
                                <a href="#">
                                    <div class="row-3-title heda">
                                        <span class="size14">委员</span>
                                        <span class="size14">(马胜利)</span>
                                        <span class="size14">(张志强)</span>
                                        <span class="size14">(李进林)</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            <div class="row-2-title heda">
                                <span class="size14">第二支部书记</span>
                                <span class="size14">(张良)</span>
                            </div>
                        </a>
                        <ul>
                            <li>
                                <a href="#">
                                    <div class="row-3-title heda">
                                        <span class="size14">委员</span>
                                        <span class="size14">(李达)</span>
                                        <span class="size14">(王磊)</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="#">
                            <div class="row-2-title heda">
                                <span class="size14">第三支部书记</span>
                                <span class="size14">(刘金亮)</span>
                            </div>
                        </a>
                        <ul>
                            <li>
                                <a href="#">
                                    <div class="row-3-title heda">
                                        <span class="size14">委员</span>
                                        <span class="size14">(吴建国)</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.tree {
    width: 100%;
}

.tree ul {
    padding-top: 20px;
    position: relative;
    webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
}

.tree li {
    float: left;
    list-style: none;
    text-align: center;
    position: relative;
    padding: 20px 5px 0 5px;
    width: 100%;
}

/*利用::before,::after作分支线*/
.tree li::before,
.tree li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    width: 50%;
    height: 20px;
    border-top: 1px solid #ccc;
}

.tree li:after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
}

.tree li:first-child::before,
.tree li:last-child::after {
    border: 0 none;
}

.tree li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 10px 0 0;
}

.tree li:first-child::after {
    border-radius: 10px 0 0 0;
}

/*删除仅只有一个分支的分支线*/
.tree li:only-child::before,
.tree li:only-child::after {
    border: none;
}

.tree li:only-child {
    padding-top: 0;
}

/*添加仅只有一个分支的下分支线*/
.tree ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
}

.tree a {
    font-size: 12px;
    display: inline-block;
    color: #666;
    text-decoration: none;
    border-radius: 5px;
}

.heda {
    box-shadow:  0px 0px 16px 0px rgba(211, 114, 109, 0.6) inset;
    border: solid .01rem #d86671;
    border-radius: .04rem;
    font-size: .14rem;
    color: #fff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.row-1-title{
    width: 1.5rem;
    height: .33rem;
    max-width: 150px;
    max-height: 33px;
}
.row-2-title{
    width: 1rem;
    height: .56rem;
    max-width: 100px;
    max-height: 56px;
}
.row-3-title{
    width: 1rem;
    height: 1.04rem;
    max-width: 100px;
    max-height: 104px;

    justify-content: flex-start;
    span{
        margin-top: .04rem;
    }
}
</style>