<!-- 智慧党建 -->
<template>
    <div>
        <div class="page_temp page_temp_left">
            <item-title :type="'left'" :page="'party'" :name="'组织建设'" />
            <div class="contentBox zzjs">
                <line-box :page="'party'" />
                <div class="zzjs_item_top">
                    <div class="item" v-for="(item, index) in zzjs_data" :key="index">
                        <img :src="item.img">
                        <div>
                            <span :class="item.type.length > 3 ? 'text_type' : ''">{{ item.type }}</span>
                            <span class="size20">{{ item.num }}</span>
                            <span>{{ item.unit }}</span>
                        </div>
                    </div>
                </div>
                <div class="split-line"></div>
                <div class="search">
                    <i class="el-icon-search size17" v-if="!searchState"></i>
                    <input type="text" class="size14 searchInput" v-model="searchText" v-if="!searchState"
                        placeholder="党员查询">
                    <span v-if="searchState" style="padding-left: .04rem;">{{ searchText }}</span>
                    <i class="el-icon-close" v-if="searchState" @click="searchText = '', searchState = false"></i>
                    <div class="search-btn" @click="searchLand">搜索</div>
                    <!-- <div class="searchListBox" v-if="searchState">
                        <ul class="searchList" v-if="landSearchList">
                            <li @click="landWinPop = true, page_temp_opcity = 0, landSearchList = false"
                                v-for="(item, index) in landSearchData" :key="index">
                                <span>{{ item.ownerName }}</span>
                                <span>{{ item.name + item.id }}</span>
                            </li>
                        </ul>
                    </div> -->
                </div>
                <div class="tab_line" style="margin-top: .2rem;">
                    <div class="tab_item" :class="ldbz_active == 1 ? 'ldbz_active' : ''" @click="ldbz_active = 1">
                        <span>领导班子</span>
                    </div>
                    <div class="tab_item" :class="ldbz_active == 2 ? 'ldbz_active' : ''" @click="ldbz_active = 2">
                        <span>党组织结构</span>
                    </div>
                </div>
                <!-- 领导班子 -->
                <div v-show="ldbz_active == 1" class="committee scrollbar">
                    <div v-for="(item, index) in v_cun_committee" class="committee-item" :key="index">
                        <div class="img"><img :src="item.pictureUrl||party_noImg" alt=""></div>
                        <ul>
                            <li><span></span>{{ item.memberName }}</li>
                            <li><span></span>{{ item.partyJob }}</li>
                            <li><span>分管工作</span>:&nbsp;{{ item.pluralityJob }}</li>
                        </ul>
                    </div>
                </div>
                <!-- 党组织结构 -->
                <div class="party_structure scrollbar" v-show="ldbz_active == 2">
                    <!-- <tree-map /> -->
                    <!-- 书记 -->
                    <div class="item-title">
                        <span>{{partyStructure.fist?partyStructure.fist[0].memberName:''}}</span>
                        <span>书记</span>
                    </div>
                    <ul>
                        <li v-for="(item,index) in partyStructure.second" :key="index"><span>{{item.memberName}}</span><span>{{item.partyJob}}</span></li>
						<li style="height: 0;opacity: 0;"></li>
						<li style="height: 0;opacity: 0;"></li>
					</ul>
                </div>
            </div>
            <item-title :type="'left'" :page="'party'" :name="'党务公开'" />
            <div class="contentBox dwgk">
                <line-box :page="'party'" />
                <div class="essat_list">
                    <div class="essat_item" v-for="(item, index) in dwgk_data" @click="getDwPartyInfo(item.id,1),messageActive = 1" :key="index">
                        <div class="img"><img :src="item.noticeImgUrl"></div>
                        <div class="text">
                            <span>{{ item.noticeTitle }}</span>
                            <span>{{ item.createTime.split(' ')[0] }}</span>
                            <span>{{ item.abstract }}</span>
                        </div>
                    </div>
                </div>
            </div>
			<!-- 党务公开弹窗 -->
			<message-pop class="message-pop" :option="dwOption" @clearPop="clearPop" style="top: 7.2rem;" v-if="messageActive == 1">
				<div class="pop-content">
					<span class="title">{{dwPartyInfo.noticeTitle}}</span>
					<div class="v-html" v-html="dwPartyInfo.noticeContent"></div>
				</div>
			</message-pop>
        </div>
        <div class="page_temp page_temp_right">
            <item-title :type="'right'" :page="'party'" :name="'选举投票'" />
            <div class="contentBox vote">
                <line-box :page="'party'" />
                <div class="title">
                    <div>优秀党员选举<span class="size12">（投票结束）</span></div>
                    <span class="size12">单位&nbsp;(&nbsp;票&nbsp;)</span>
                </div>
                <div class="chart scrollbar">
                    <div class="chart-item" v-for="(item, index) in xjtp_data" :key="index">
                        <span class="name">{{ item.memberName }}</span>
                        <div class="line">
                            <span :style="{ width: item.candidateNum / xjtp_standard * 100 + '%' }"></span>
                        </div>
                        <span class="num">{{ item.candidateNum }}</span>
                    </div>
                </div>
            </div>
            <item-title :type="'right'" :page="'party'" :name="'通知公告'" />
            <div class="contentBox " style="height: 3.2rem;">
                <line-box :page="'party'" />
                <div class="essat_list">
                    <div class="essat_item" v-for="(item, index) in tzgg_data" @click="getDwPartyInfo(item.id,2),messageActive = 2" :key="index">
                        <div class="img"><img :src="item.noticeImgUrl"></div>
                        <div class="text">
                            <span>{{ item.noticeTitle }}</span>
                            <span>{{ item.createTime.split(' ')[0] }}</span>
                            <span>{{ item.noticeDescribe }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <item-title :type="'right'" :page="'party'" :name="'党员考核'" />
            <div class="contentBox assess">
                <line-box :page="'party'" />
                <div class="select-box">
                    <select class="select" v-model="assessYear">
                        <option>2022</option>
                        <option>2021</option>
                        <option>2020</option>
                    </select>
                    <div class="search">
                        <i class="el-icon-search size17" v-if="!searchState"></i>
                        <input type="text" class="searchInput" v-model="searchPartyText" v-if="!searchState"
                            placeholder="请输入">
                        <span v-if="searchState" style="padding-left: .04rem;">{{ searchPartyText }}</span>
                        <i class="el-icon-close" v-if="searchState" @click="searchPartyText = '', searchState = false"></i>
                        <div class="search-btn" @click="getAssessList()">搜索</div>
                    </div>
                </div>
                <div class="table">
                    <div>
                        <span>分类</span>
                        <span>标题</span>
                        <span>对象</span>
                        <span>得分</span>
                    </div>
                    <ul class="scrollbar"  v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
                        <li v-for="(item, index) in dykh_data" :key="index">
                            <span>{{ item.addType==1?'岗位绩效':item.addType==2?'党风廉政':'负面清单' }}</span>
                            <span>{{ item.assessTitle }}</span>
                            <span>{{ item.checkName }}</span>
                            <span>{{ item.assessNumber }}</span>
                        </li>
                    </ul>
                </div>
            </div>
			<!-- 通知公告弹窗 -->
			<message-pop class="message-pop" :option="ggOption" @clearPop="clearPop" style="top: 3.3rem;" v-if="messageActive == 2">
				<div class="pop-content">
					<span class="title">{{ggPartyInfo.noticeTitle}}</span>
					<div class="v-html" v-html="ggPartyInfo.noticeContent"></div>
				</div>
			</message-pop>
        </div>

        <dot-pop :name="'党员信息'" :party="true" :x="party_member_info.shape[0]"  :y="party_member_info.shape[1]" :z="10" v-if="v_if_show_pane && party_member_info" @closePane="closePane">
            <ul id="id_pane_content">
                <li><span>党员姓名</span>&nbsp;:&nbsp;{{ party_member_info.xm }}</li>
                <li><span>党员类型</span>&nbsp;:&nbsp;{{ party_member_info.dylx }}</li>
                <li><span>联系电话</span>&nbsp;:&nbsp;{{ party_member_info.lxdh }}</li>
                <li><span>入党时间</span>&nbsp;:&nbsp;{{ party_member_info.rdsj }}</li>
                <li><span>党组织关系状态</span>&nbsp;:&nbsp;{{ party_member_info.gllx }}</li>
                <li><span>所属支部</span>&nbsp;:&nbsp;{{ party_member_info.sszb }}</li>
            </ul>
        </dot-pop>
    </div>

</template>

<script>
import lineBox from '../components/line-border-box.vue'
import itemTitle from '../components/item-title.vue'
import http from '../util/http.js';
import { getDiShiZhouCodeByCunCode } from '../util/common.js';
import { getLonLatArray, addPoint, cleanMarkerListByType, getMakerById } from '../util/drawShape.js';
import img_dy from "../assets/marker/p_xcdy.png";
import dotPop from "@/components/dot-pop.vue"
import { getAssessList, getCount, getMapVote, getNoticeInfo, getNoticeList, getPartyMember,getPartyJob,getStructure } from "@/api/party.js"
import treeMap from "@/components/treeMap.vue"
import messagePop from '../components/message-pop.vue'
import config from '@/config.js'

var g_xqid = localStorage.getItem('tenantCode'); //config.TENANT_CODE; //'420626107204';
var g_dsz_id = getDiShiZhouCodeByCunCode(g_xqid);
var g_type = 'dy';

var g_handler = null;

export default {
    components: { lineBox, itemTitle, dotPop, treeMap,messagePop },
    data: function () {
        return {
            v_if_show_pane: false,
            v_dy_list: [],
			// 党员考核
			loading:true,
			// 党员考核姓名搜索
			searchPartyText:'',
            // 组织建设数据
            zzjs_data: [{
                img: require('../assets/img/party/b2.png'),
                type: '正式党员',
                num: '0',
                unit: '个'
            }, {
                img: require('../assets/img/party/b3.png'),
                type: '预备党员',
                num: '0',
                unit: '个'
            }, {
                img: require('../assets/img/party/b4.png'),
                type: '入党申请',
                num: '0',
                unit: '个'
            }, {
                img: require('../assets/img/party/b1.png'),
                type: '积极分子',
                num: '0',
                unit: '个'
            }],
			// 党员考核年份
			assessYear:2022,
            // 搜索状态
            searchState: false,
            // 搜索列表状态
            landWinPop: false,
            // 搜索内容
            searchText: '',
            // 领导班子选项
            ldbz_active: 1,
            //领导班子数据
            v_cun_committee: [],
            // 党务公开数据
            dwgk_data: [],
            // 通知公告
            tzgg_data: [],
            transfromLeft: '',
            transfromRight: '',
            // 党员考核菜单项
            dykh_active: 1,
            dykh_data: [],
            // 选举投票数据
            xjtp_data: [],
            // 选举投票基准值
            xjtp_standard: 0,
            // 党员信息
            party_member_info: {},
            options: [{
                value: '选项1',
                label: '2022'
            }, {
                value: '选项2',
                label: '2021'
            }, {
                value: '选项3',
                label: '2020'
            }],
            value: '',
			messageActive : 0,
			active1 : 0,
			// 党务公开弹窗配置
			dwOption: {
			    width: 400,
			    height: 254,
			    type: 'left',
			    name: '党务公开详情'
			},
			// 通知公告弹窗配置
			ggOption:{
				width: 600,
				height: 400,
				type: 'right',
				name: '通知公告详情'
			},
			// 党组织结构相关数据
			partyStructure:{},
			// 党务公开详情
			dwPartyInfo:{},
			// 通知公告详情
			ggPartyInfo:{},
            // 领导班子默认图
            party_noImg:require('../assets/img/party/no_img.png')
        }
    },
	watch:{
		assessYear:function(newVal,oldVal){
			this.getAssessList()
		}
	},
    mounted() {
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.init();  
            }
        }, 500)
    },
    methods: {
        init() {
            g_xqid = localStorage.getItem('tenantCode');
            // 获取党员考核列表
            this.getAssessList()
            // 获取统计数据
            this.getCount()
            // 获取选举投票数据
            this.getMapVote()
            // 获取党务公开-通知公告列表
            this.getNoticeList()
			// 获取领导班子相关数据
			this.getPartyJob()
			// 获取党组织结构相关数据
            this.getStructure()
            
            this.requestDangYuanList();

            this.$parent.v_show_circle = false;
            this.$parent.v_show_house = false;
            this.$parent.v_show_hu = false;
            this.$parent.show2DMap();

            g_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            g_handler.setInputAction((movement) => {
                var pick = viewer.scene.pick(movement.position);
                if (Cesium.defined(pick)) {
                    if (pick.id) {
                        //选中
                        var _entity = pick.id;
                        var _index = _entity.data.id;
                        this.party_member_info = this.v_dy_list[_index];
                        console.log(this.party_member_info);
                        this.v_if_show_pane = true;
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

        },

        requestDangYuanList: function () {
            var _str_filter = "SSCID= '" + g_xqid + "' ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p" + g_dsz_id + ":t05_dy"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["id", "xm", "dylx", "rdsj", "lxdh", "gllx", "sszb"],
                    "orderby": 'id'
                },
                "maxFeatures": 100
            }
            http.requestIServerWithPage(_sqlParameter, 0, 1000, (result) => {
                console.log('--村党员回调--', result.totalCount);
                if (result.features.length > 0) {
                    this.v_dy_list = [];
                    for (var i = 0; i < result.features.length; i++) {
                        var _feature = result.features[i];
                        var _item = {
                            id: i,
                            xm: _feature.fieldValues[1],
                            dylx: _feature.fieldValues[2],
                            rdsj: _feature.fieldValues[3].substring(0, 10) || "暂无记录",
                            lxdh: _feature.fieldValues[4],
                            gllx: _feature.fieldValues[5],
                            sszb: _feature.fieldValues[6],
                            type: 1,
                            shape: [_feature.geometry.center.x, _feature.geometry.center.y]
                        };
                        this.v_dy_list.push(_item);
                        var _html = '';
                        var _config = { image: img_dy, width: 20, height: 30 , z: this.$parent.getZ()};
                        addPoint(g_type, _item.id, '', _item.shape, _html, _config);
                    }

                }
            });
        },

        closePane: function () {
            this.v_if_show_pane = false;
        },
       
        // 点击搜索
        searchLand: function () {

        },
        // 获取党员考核数据
        getAssessList: function () {
			this.loading = true
            let json = {
                pageSize: 5,
                pageNum: 1,
				assessYear:this.assessYear
            }
			if(this.searchPartyText){
				json.checkName = this.searchPartyText
			}
            getAssessList(json).then(res => {
				this.loading = false
				this.dykh_data = res.rows
            })
        },
        // 获取统计数据
        getCount: function () {
            getCount().then(res => {
				res.data.forEach(item =>{
					console.log(item)
					switch(item.memberType){
						case '正式党员':  this.zzjs_data[0].num = item.num;  break;
						case '预备党员':  this.zzjs_data[1].num = item.num;  break;
						case '入党申请':  this.zzjs_data[2].num = item.num;  break;
						case '积极分子':  this.zzjs_data[3].num = item.num;  break;
					}
				})
            })
        },
        // 获取选举投票数据
        getMapVote: function () {
            getMapVote().then(res => {
                this.xjtp_data = res.data.candidateList
				// candidateNum  选票数量
				res.data.candidateList.forEach(item =>{
					this.xjtp_standard += item.candidateNum
				})
            })
        },
        // 获取党务公开-党务公开
        getNoticeList: function () {
            for (let i of [1, 2]) {
                let json = {
                    noticeType: i,
                    pageNum: 1,
                    pageSize: 4 - i
                }
                getNoticeList(json).then(res => {
                    if (i == 1) {  //通知公告
						this.tzgg_data = res.rows
                    } else {  //党务公开
						this.dwgk_data = res.rows
                    }
                })
            }
        },
		// 获取领导班子相关数据
		getPartyJob:function(){
			getPartyJob().then(res =>{
				this.v_cun_committee = res.data
			})
		},
		// 关闭弹窗
		clearPop: function () {
		    this.messageActive = 0
		    this.active1 = 0
		},
		// 查看党务公开详情
		getDwPartyInfo:function(id,type){
			getNoticeInfo(id).then(res =>{
				if(type == 1){
					this.dwPartyInfo = res.data
				}else{
					this.ggPartyInfo = res.data
				}
				
			})
		},
		// 获取党组织结构相关数据
		getStructure:function(){
			getStructure().then(res =>{
				this.partyStructure = res.data
			})
		}
    },

    destroyed() {
        cleanMarkerListByType(g_type);
        //注销选中事件
        this.v_if_show_pane = false;
        g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        g_handler = g_handler && g_handler.destroy();
    }
}
</script>

<style scoped>
.cls-close-hu {
    float: right;
    color: red;
    cursor: pointer;
}
.v-html >>> img{
	width: 100% !important;
}
.v-html >>> p,
.v-html >>> div,
.v-html >>> span{
	font-size: 0.14rem !important;
	font-weight: 400 !important;
	
	color: #fff !important;
}
</style>
<style lang="scss" scoped>
.zzjs_item_top {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 .15rem;
    box-sizing: border-box;

    .item {
        width: 48%;
        height: .6rem;
        position: relative;
        margin-bottom: .14rem;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        div {
            width: 100%;
            position: absolute;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 42%;
            box-sizing: border-box;

            span {
                font-size: .14rem;
            }

            .text_type {
                display: flex;
                flex-wrap: wrap;
                width: .4rem;
            }

            .size20 {
                font-size: .2rem;
                color: #fea6af;
                margin: 0 .1rem;
            }
        }
    }
}

.search {
    width: calc(100% - .3rem);
    height: .34rem;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: .17rem;
    border: solid 1px #fea6af;
    padding-left: .16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;

    input {
        flex: 1;
        width: 40%;
        padding-left: .04rem;
        margin-left: .04rem;
        border: none;
        outline: none;
        background-color: transparent;
        border-left: .02rem solid #ddd;
        color: #fff;
    }

    span {
        font-size: .14rem;
        width: 50%;
    }

    i {
        font-size: .17rem;
        cursor: pointer;
    }

    .search-btn {
        height: 100%;
        border: .01rem solid transparent;
        padding: 0 .1rem;
    }

    .search-btn {
        background-image: linear-gradient(to top, #fb4456c6, #efb6bb);
        background-blend-mode: normal, normal;
        margin-right: .01rem;
        border-radius: .15rem;
        width: .8rem;
        height: calc(100% - 0.02rem);
        box-sizing: border-box;
        font-size: .14rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding: 0;
        cursor: pointer;
    }

    .searchListBox {
        width: 100%;
        position: absolute;
        z-index: 999;
        left: 0;
        top: 100%;
        display: flex;
        justify-content: center;

        .searchList {
            width: 90%;
            height: auto;
            border-radius: 0px 0px .08rem .08rem;
            border: solid .01rem #fea6af;
            background-color: #00000092;
            box-shadow: 0px 0px .06rem 0px #fea6af inset;
            padding: .1rem;
            box-sizing: border-box;

            li {
                list-style-type: none;

                span:first-child {
                    color: #1fd7fc;
                    margin-right: .2rem;
                    cursor: pointer;
                    display: inline-block;
                    width: .5rem;
                    text-align: justify;
                    text-align-last: justify;

                }
            }
        }
    }
}
.message-pop{
	font-size: 0.14rem !important;
	overflow: hidden;
	.pop-content{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		.title{
			margin: 0.1rem 0;
			display: inline-block;
			width: 100%;
			text-align: center;
		}
		.v-html{
			flex: 1;
			display: block;
			width: 100%;
			height: 20px;
			overflow: auto;
		}
	}
}
.tab_line {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 .15rem;
    box-sizing: border-box;

    .tab_item {
        width: 45%;
        height: .4rem;
        cursor: pointer;

        span {
            display: flex;
            width: 100%;
            height: 90%;
            justify-content: center;
            align-items: center;
            border-radius: .04rem;
            background-color: rgba(255, 255, 255, 0.253);
            color: #fff;
            font-size: .14rem;
        }
    }

    .ldbz_active {
        background-image: url('../assets/img/party/ldbz.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}

.zzjs {
    padding: .1rem 0 0;

    .committee {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: .18rem 0 0;
        height: 3rem;
        overflow: auto;
        box-sizing: border-box;

        .committee-item {
            width: 2.7rem;
            height: 0.9rem;
            display: flex;
            flex-direction: row;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: .18rem;

            .img {
                width: .8rem;
                height: .8rem;
                border: 1px solid #fff;
                border-radius: 50%;
                box-sizing: border-box;

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            ul {
                list-style-type: none;

                li {
                    font-size: .14rem;

                    span {
                        font-size: .14rem;
                    }
                }

                li:first-child {
                    font-size: .16rem;
                }

                margin-left: .14rem;
                height: .8rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
    }
}

.dwgk {
    width: 100%;
	height: 2.4rem;
}

.essat_list {
    width: 100%;
    display: flex;
    flex-direction: column;

    .essat_item {
        display: flex;
        margin-bottom: .1rem;
        cursor: pointer;

        .img {
            display: flex;
            overflow: hidden;
            width: 1.15rem;
            height: .9rem;
            border-radius: .1rem;
            border: .01rem solid #ddd;
            margin-right: .16rem;

            img {
                width: 100%;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            flex: 1;

            .size16 {
                font-size: .16rem;
            }

            .size14 {
                font-size: .14rem;
            }

            span {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }

    .essat_item:last-child {
        margin: 0;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    font-size: .14rem;
    margin-top: .1rem;
    color: #fff;

    tr {
        height: .32rem;
        font-size: .14rem;

        td {
            text-align: center;
            font-size: .14rem;
        }
    }

    .title {
        text-align: left;
        padding: 0 .1rem;
        width: 40%;

        div {
            width: 100%;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-size: .14rem;
        }
    }
}

table>tr:first-child {
    height: .38rem;
}

.vote {
    width: 100%;
    padding: .2rem 0 0;

    .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 .15rem;
        box-sizing: border-box;

        div {
            font-size: .16rem;
            color: #fea6af;

            span {
                font-size: .12rem;
                color: #fcc01f;
            }
        }

        span {
            font-size: .12rem;
        }
    }

    .chart {
        width: 100%;
        height: 1.4rem;
        overflow: auto;
        box-sizing: border-box;
        padding: 0 .15rem;

        .chart-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: .32rem;

            span {
                display: inline-block;
                width: 20%;
                font-size: .14rem;
            }

            span:last-child {
                width: 10%;
                text-align: right;
            }

            .line {
                height: .2rem;
                width: 70%;

                span {
                    display: inline-block;
                    height: 100%;
                    border-radius: .05rem;
                    background-image: linear-gradient(180deg,
                            #fea6af 0%,
                            #fb4457 100%)
                }
            }
        }

        .chart-item:first-child {
            margin-top: .1rem;
        }
    }
}

#id_pane_content {
    list-style-type: none;
    padding: .2rem 0;

    li {
        line-height: .32rem;
        font-size: .14rem;

        span {
            display: inline-block;
            width: 1.2rem;
            text-align: justify;
            text-justify: distribute-all-lines;
            text-align-last: justify;
            font-size: .14rem;
        }
    }
}

.party_structure {
    width: 100%;
    height: 3rem;
    overflow: auto;
    padding: .2rem 0 .2rem 0;
    box-sizing: border-box;

    .item-title,
    li {
        box-shadow: 0px 0px 16px 0px rgba(252, 86, 103, 0.6) inset;
        border-radius: .04rem;
        font-size: .14rem;
        color: #fff;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .item-title {
        width: 1.8rem;
        height: .4rem;
        margin: 0 auto;
        flex-direction: row;
        justify-content: space-around;
    }

    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    li {
        list-style-type: none;
        width: .96rem;
        height: .71rem;
        margin-top: .2rem;
    }
}

.split-line {
    margin: .1rem 0;
}

.assess {
    width: 100%;
    padding: .2rem 0 0;
    .select-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 .15rem;
        box-sizing: border-box;
        .select {
            width: 1.3rem;
            height: .33rem;
            border-radius: .17rem;
            border: solid .01rem #fea6af;
            outline: none;
            background-color: transparent;
            color: #fff;
            padding: 0 .15rem;
            box-sizing: border-box;
            font-size: .14rem;
        }

        option {
            color: #333;
            line-height: .32rem;
        }
        .search{
            width: 1.8rem;
            height: .34rem;
            margin: 0;
            .searchInput::placeholder{
                color: #ddd;
            }
            .search-btn{
                width: .5rem;
                height: .3rem;
            }
        }
    }
    .table{
        div,
        li{
            display: flex;
            margin-top: .1rem;
            width: 100%;
            height: .28rem;
            align-items: center;
            color: #fff;
            box-sizing: border-box;
            background-color: #f743553c;
            padding: 0 .15rem;
            span{
                display: flex;
                height: 100%;
				line-height: 0.28rem;
				
				display: -webkit-box;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
            }
            span:nth-child(1){
                width: 26%;
            }
            span:nth-child(2){
                width: 40%;
            }
            span:nth-child(3){
                width: 20%;
            }
            span:nth-child(4){
                width: 14%;
                justify-content: center;
            }
        }
        div{
            background-color: #f7435586;
            width: calc(100% - .3rem);
            margin: .14rem auto 0;
        }
        ul{
            width: 100%;
            height: 1.7rem;
            overflow: auto;
            padding: 0 .15rem;
            box-sizing: border-box;
        }
        .scrollbar{
            padding-bottom: .2rem;
        }
    }
}

</style>