import axios from "../axios/index"

// 获取党员考核列表
export const getAssessList = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/party/assessList',data)
}
// 统计数量
export const getCount = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/party/count',data)
}
// 获取选举投票数据
export const getMapVote = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/party/mapVote',data)
}
// 获取党务公开-通知公告详情
export const getNoticeInfo = (id) => {
    return axios.get('/onemap/portal/party/notice/'+id)
}
// 获取党务公开-通知公告列表
export const getNoticeList = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/party/noticeList',data)
}
// 获取党员图层
export const getPartyMember = (data) => {
    return axios.get('/onemap/portal/party/partyMember',data)
}
// 获取领导班子相关数据
export const getPartyJob = () => {
	let data = {
	    tenantId:localStorage.getItem('tenantId')
	}
    return axios.get('/onemap/portal/party/partyJob',data)
}
// 获取党组织结构相关数据
export const getStructure = () => {
	let data = {
	    tenantId:localStorage.getItem('tenantId')
	}
    return axios.get('/onemap/portal/party/structure',data)
}